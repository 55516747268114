import { Box, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import SellBikeImage from '../../assets/sell-bike-ad.png';
import { UniversalButton } from '../../components';
import routeConfiguration from '../../routing/routeConfiguration';
import { pathByRouteName } from '../../util/routes';

export const SectionSellBikeAd = () => {
  const history = useHistory();
  const intl = useIntl();

  const handleSellClick = () => {
    const path = pathByRouteName('NewListingPage', routeConfiguration());
    history.push(path);
  };

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      maxWidth={1320}
      mx="auto"
      overflow="hidden"
      sx={{
        maxWidth: 1320,
        borderRadius: 4,
        '@media (max-width: 1439px)': { maxWidth: '100vw', borderRadius: 0 },
      }}
    >
      <Box component="img" src={SellBikeImage} alt="Sell your bike" width="auto" height={{ xs: 'auto', md: 400 }} />
      <Stack
        width="100%"
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        bgcolor="black"
        px={{ xs: 2, md: 3 }}
        py={3}
        gap={2}
        sx={{
          '& button': {
            width: { xs: '100%', lg: 280 },
          },
        }}
      >
        <Typography color="#FCFCFD" fontSize={24} fontWeight={500}>
          {intl.formatMessage({ id: 'landing_page.sell_bike_ad.title' })}
        </Typography>
        <Typography color="#FCFCFD" fontSize={16} fontWeight={400} mb={2} mr={6}>
          {intl.formatMessage({ id: 'landing_page.sell_bike_ad.description' })}
        </Typography>
        <UniversalButton type="contrast" onClick={handleSellClick} mt={2}>
          {intl.formatMessage({ id: 'landing_page.sell_bike_ad.button' })}
        </UniversalButton>
      </Stack>
    </Stack>
  );
};
