import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

import classNames from 'classnames';
import css from './SectionInfo.module.css';

import Buy1 from '../../../assets/info/buy_1.png';
import Buy2 from '../../../assets/info/buy_2.png';
import Buy3 from '../../../assets/info/buy_3.png';

import Sell1 from '../../../assets/info/sell_1.png';
import Sell2 from '../../../assets/info/sell_2.png';
import Sell3 from '../../../assets/info/sell_3.png';

function SectionInfo(props) {
  const { intl } = props;
  const [currentSection, setCurrentSection] = useState('sell');

  const tabConfiguration = { sell: 3, buy: 3 };

  const itemImageConfiguration = {
    sell: [Sell1, Sell2, Sell3],
    buy: [Buy1, Buy2, Buy3],
  };

  const sellSliderClassNames = classNames(css.slider, { [css.selectSlider]: currentSection === 'sell' });
  const buySliderClassNames = classNames(css.slider, { [css.selectSlider]: currentSection === 'buy' });
  const sellSliderTextClassNames = classNames(css.slidertext, { [css.selectedSliderText]: currentSection === 'sell' });
  const buySliderTextClassNames = classNames(css.slidertext, { [css.selectedSliderText]: currentSection === 'buy' });

  return (
    <Stack
      bgcolor="#F5F5F6"
      width="100%"
      alignItems="center"
      mt={10}
      py={6}
      sx={{
        px: 3,
        '@media (max-width: 1439px)': { maxWidth: '100vw', px: 3, mt: 0 },
        '@media (max-width: 767px)': { maxWidth: '100vw', px: 2 },
      }}
    >
      <Typography
        variant="h2"
        color="textPrimary"
        fontSize={24}
        fontWeight={600}
        mb={2}
        sx={{ '@media (max-width: 1439px)': { mt: 2, mb: 3 } }}
      >
        {intl.formatMessage({ id: 'landing_page.section_info.label' })}
      </Typography>

      <div className={css.sliderRoot}>
        <div className={sellSliderClassNames} onClick={() => setCurrentSection('sell')}>
          <span className={sellSliderTextClassNames}>
            {intl.formatMessage({ id: 'landing_page.section_info.sell' })}
          </span>
        </div>
        <div className={buySliderClassNames} onClick={() => setCurrentSection('buy')}>
          <span className={buySliderTextClassNames}>{intl.formatMessage({ id: 'landing_page.section_info.buy' })}</span>
        </div>
      </div>

      <Stack
        mt={3}
        direction={{ xs: 'column', md: 'row' }}
        maxWidth={1320}
        gap={{ xs: 4, md: 7 }}
        sx={{ '@media (max-width: 1439px)': { mt: 4 } }}
      >
        {Array.from({ length: tabConfiguration[currentSection] }, (_value, index) => (
          <Stack key={`${currentSection}_${index}`} flex={1} gap={2} className={css.fadeIn}>
            <Box
              component="img"
              width="100%"
              height="240px"
              borderRadius="8px"
              sx={{ objectFit: 'cover' }}
              src={itemImageConfiguration[currentSection][index]}
            />
            <Typography variant="h3" color="textPrimary" fontSize={24} fontWeight={600}>
              {intl.formatMessage({ id: `landing_page.section_info.${currentSection}.title_${index}` })}
            </Typography>
            <Typography variant="body1" color="textPrimary" fontSize={16}>
              {intl.formatMessage({ id: `landing_page.section_info.${currentSection}.description_${index}` })}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default SectionInfo;
