import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import classNames from 'classnames';
import config from '../../config';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';

import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
  UniversalButton,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';

import thumbnailImg from '../../assets/gearroThumbnail.png';

import { getListingsById } from '../../ducks/marketplaceData.duck';
import { addCurrentUserFavoriteListing, removeCurrentUserFavoriteListing } from '../../ducks/user.duck';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import css from './LandingPage.module.css';
import SectionCategories from './SectionCategories/SectionCategories';
import { SectionHero } from './SectionHero/SectionHero';
import SectionInfo from './SectionInfo/SectionInfo';
import { SectionPopularBrands } from './SectionPopularBrands/SectionPopularBrands';
import SectionRecentItems from './SectionRecentItems/SectionRecentItems';
import { SectionSellBikeAd } from './SectionSellBikeAd';

export function LandingPageComponent(props) {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    recentListings,
    onLocaleChange,
    currentLocale,
    likedListingsIds,
    addUserFavorite,
    removeUserFavorite,
    currentUserId,
    recentPartListings,
  } = props;

  const { siteTitle } = config;
  const schemaTitle = intl.formatMessage({ id: 'Landing_page.schema_title' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'landing_page.schema_description' });
  const host = typeof window !== 'undefined' && window.location && window.location.host;
  const schemaImage = `${host}${thumbnailImg}`;

  const handleSubmit = values => {
    const searchParams = { keywords: values?.keywords };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  const handleViewMore = () => {
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), { type: 'bike' }));
  };

  const renderRecentListings = (listings, label) => (
    <div className={css.mostRecentItemsContainer}>
      <div className={css.mostRecentItemsHeader}>
        <h2 className={css.mostRecentItemsContainerTitle}>{label}</h2>
        <UniversalButton type="secondary" onClick={handleViewMore}>
          {intl.formatMessage({ id: 'landing_page.view_more' })}
        </UniversalButton>
      </div>
      <SectionRecentItems
        recentListings={listings}
        intl={intl}
        likedListingsIds={likedListingsIds}
        addUserFavorite={addUserFavorite}
        removeUserFavorite={removeUserFavorite}
        currentUserId={currentUserId}
      />
    </div>
  );

  const renderBuyButton = (listingType, label) => (
    <div className={css.searchItemsContainer}>
      <NamedLink
        name="SearchPage"
        params={{ type: listingType }}
        className={classNames(css.heroButton)}
        state={{ prevPath: history.location.pathname }}
      >
        {label}
      </NamedLink>
    </div>
  );

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: thumbnailImg, width: 842, height: 595 }]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
      displayBanner
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer onLocaleChange={onLocaleChange} currentLocale={currentLocale} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <SectionHero />

          <Box mt={6} mb={10}>
            <SectionCategories intl={intl} />
          </Box>

          <SectionPopularBrands intl={intl} history={history} />

          {recentListings &&
            renderRecentListings(
              recentListings.slice(0, 5),
              intl.formatMessage({ id: 'landing_page.most_recent_label' })
            )}
          {recentListings &&
            renderRecentListings(
              recentListings.slice(5, 10),
              intl.formatMessage({ id: 'landing_page.most_popular_label' })
            )}

          {/* {renderBuyButton('bike', intl.formatMessage({ id: 'landing_page.buy_bike_button' }))}
          {recentPartListings?.length > 0 &&
            renderRecentListings(recentPartListings, intl.formatMessage({ id: 'landing_page.most_recent_part_label' }))}
          {recentPartListings?.length > 0 &&
            renderBuyButton('parts', intl.formatMessage({ id: 'landing_page.buy_parts_button' }))} */}

          <Box mt={6}>
            <SectionSellBikeAd />
          </Box>

          <div className={css.infoSection}>
            <SectionInfo intl={intl} />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer className={css.footer} />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
}

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  history: object.isRequired,
  location: object.isRequired,

  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { recentListingIds, recentPartListingsIds } = state.LandingPage;

  const { currentUserFavoriteListingsIds, currentUser } = state.user;

  const recentListings = getListingsById(state, recentListingIds);
  const recentPartListings = getListingsById(state, recentPartListingsIds);

  return {
    scrollingDisabled: isScrollingDisabled(state),
    recentListings,
    recentPartListings,
    likedListingsIds: currentUserFavoriteListingsIds,
    currentUserId: currentUser?.id?.uuid,
  };
};

const mapDispatchToProps = dispatch => ({
  addUserFavorite: (listingId, listingType) => dispatch(addCurrentUserFavoriteListing(listingId, listingType)),
  removeUserFavorite: listingId => dispatch(removeCurrentUserFavoriteListing(listingId)),
});

const LandingPage = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), injectIntl)(LandingPageComponent);

export default LandingPage;
