import { Box } from '@mui/material';
import Slide1 from '../../../assets/carousel/slide-1.webp';
import Slide2 from '../../../assets/carousel/slide-2.webp';
import Slide3 from '../../../assets/carousel/slide-3.webp';
import Slide1Small from '../../../assets/carousel/slide-small-1.webp';
import Slide2Small from '../../../assets/carousel/slide-small-2.webp';
import Slide3Small from '../../../assets/carousel/slide-small-3.webp';
import { HeroCarousel } from './carousel/HeroCarousel';

const ITEMS = [
  { id: 'slide_1', src: Slide1, srcSmall: Slide1Small, color: 'primary.contrastText', to: 'NewListingPage' },
  { id: 'slide_2', src: Slide2, srcSmall: Slide2Small, color: 'primary.contrastText', to: 'NewListingPage' },
  { id: 'slide_3', src: Slide3, srcSmall: Slide3Small, color: 'textPrimary', to: 'SearchPage' },
];

export const SectionHero = () => {
  return (
    <Box
      width="100%"
      height={{ xs: 375, sm: 400 }}
      mt={{ xs: 0, sm: 4 }}
      borderRadius={{ xs: 0, sm: '12px' }}
      overflow="hidden"
      sx={{
        maxWidth: 1320,
        mx: 'auto',
        '@media (max-width: 1439px)': { maxWidth: theme => `calc(100vw - ${theme.spacing(6)})`, mx: 3 },
        '@media (max-width: 767px)': { maxWidth: theme => `calc(100vw - ${theme.spacing(4)})`, mx: 2 },
        '@media (max-width: 600px)': { maxWidth: '100vw', mx: 0 },
        '@media (min-width: 320px) and (max-width: 600px)': { height: 500 },
      }}
    >
      <HeroCarousel items={ITEMS} />
    </Box>
  );
};
