import { Box, Stack, Typography } from '@mui/material';

export const HorizontalList = ({ title, children }) => (
  <Stack
    direction="column"
    gap={3}
    mx="auto"
    overflow="hidden"
    sx={{
      maxWidth: 1320,
      '@media (max-width: 1439px)': { maxWidth: '100vw', px: 3 },
      '@media (max-width: 767px)': { maxWidth: '100vw', px: 2 },
    }}
  >
    {title && (
      <Typography variant="h2" fontSize={24} fontWeight={600}>
        {title}
      </Typography>
    )}
    <Box
      width="100%"
      display="flex"
      flexWrap="nowrap"
      gap={{ xs: 2, sm: 3 }}
      overflow={{ xs: 'scroll', md: 'hidden' }}
      sx={{
        '&::-webkit-scrollbar': { display: 'none' },
        '@media (max-width: 1439px)': {
          width: theme => `calc(100vw - ${theme.spacing(2)})`,
          ml: -2,
          px: 2,
        },
        '@media (max-width: 767px)': {
          width: theme => ({ xs: '100vw', md: `calc(100vw - ${theme.spacing(3)})` }),
          ml: -2,
          px: 2,
        },
      }}
    >
      {children}
    </Box>
  </Stack>
);
